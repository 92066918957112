import { FC, Fragment, useContext, useMemo } from "react";
import { HeroWrapper, IconWrapper } from "./Hero.styled";
import parse from "html-react-parser";
import { setDefaultImage } from "common/Utils/defaults";
import { previewTypes } from "common/context/Preview/PreviewContext";
import { PreviewContext } from "common/context/Preview";
import { FaPlay } from "react-icons/fa";
import { cropImage } from "@brightsites/flow-core/lib/utils/cropImage";
import BounceLoader from "react-spinners/BounceLoader";
import GalleryIcon from "common/icons/Gallery";

type Props = {
  article: {
    hierarchy: { name: string }[];
    sections: {
      name: string;
    }[];
    extra?: {
      isBreakingNews: boolean;
      isShowSponsoredCapsule?: boolean;
    };
    lead: string;
    titleShort: string;
    path: string;
    liveblog: {
      data: {
        status: number;
      };
    };
    hero: {
      type?: string;
      data: {
        gallery: {
          data: {
            url: string;
          };
        }[];
        url: string;
        bundle: string;
      };
    }[];
  };

  size: number;
  cropData?: object;
};

const Hero: FC<Props> = ({ article, size, cropData }) => {
  const { preview } = useContext<previewTypes>(PreviewContext);

  const heroType = useMemo(() => {
    try {
      return article?.hero ? article?.hero[0].data.bundle : "";
    } catch (error) {
      console.error("Error calculating heroType:", error);
      return ""; // Default value in case of an error
    }
  }, [article]);
  const image = useMemo(() => {
    const imageItem = article?.hero?.filter((item) => item.type === "image")[0];
    if (imageItem) {
      return imageItem.data.url;
    }
    return setDefaultImage(article?.sections[0]?.name);
  }, [article]);

  const isVideo = useMemo(() => {
    try {
      return (
        article?.hero?.some((item: any) =>
          ["video-youtube", "dailymotion"].includes(item?.type)
        ) || false
      );
    } catch (error) {
      console.error("Error determining video type:", error);
      return false; // default to false in case of an error
    }
  }, [article]);

  const isGallery = useMemo(() => {
    try {
      return (
        article?.hero?.some((item: any) => ["gallery"].includes(item?.type)) ||
        false
      );
    } catch (error) {
      console.error("Error determining video type:", error);
      return false; // default to false in case of an error
    }
  }, [article]);


  const daily_motion_image =
    article?.hero && article?.hero[1] && article?.hero[1].data
      ? article?.hero[1].data.url
      : setDefaultImage(article?.sections[0]?.name);
  const galleryPreviewImage =
    article?.hero?.[0]?.data?.gallery?.[0]?.data?.url ??
    "/images/placeholder.jpg";

  const titleShort = article?.titleShort;
  const description = article?.lead;
  const path = article?.path;
  const breakingNews = article?.extra?.isBreakingNews;

  const dailymotion = article?.hero?.[0]?.type === "dailymotion";
  const isLive = useMemo(() => {
    try {
      return article.liveblog?.data.status === 1;
    } catch (error) {
      console.error("Error determining if article is live:", error);
      return false; // default to false in case of an error
    }
  }, [article]);

  const isSponsored = useMemo(() => {
    try {
      return article.extra && article.extra?.isShowSponsoredCapsule;
    } catch (error) {
      console.error("Error determining if article is sponsored:", error);
      return false; // default to false in case of an error
    }
  }, [article]);
  const video_youtube = article?.hero?.[0]?.type === "video-youtube";

  const renderImageUrl = (img: string, mobile: boolean) => {
    if (size === 1) {
      return mobile
        ? `${img}?${cropImage(cropData)}width=380&height=350&crop=380:350`
        : `${img}?${cropImage(cropData)}width=950&height=450&crop=950:649`;
    } else {
      return mobile
        ? `${img}?${cropImage(cropData)}width=380&height=350&crop=380:350`
        : `${img}?${cropImage(cropData)}width=640&height=525&crop=640:525`;
    }
  };
  if (!article) {
    return null;
  }

  return (
    <HeroWrapper size={size}>
        <a href={preview ? "#" : path}>
          {isVideo && (
            <IconWrapper>
              {" "}
              <FaPlay />
            </IconWrapper>
          )}
          {isGallery && (
            <IconWrapper>
              {" "}
              <GalleryIcon />
            </IconWrapper>
          )}
          {isLive && (
            <span className="live">
              {" "}
              <BounceLoader size="15px" color="#C80015" />{" "}
              <p style={{ marginLeft: 6 }}>live</p>
            </span>
          )}
          {dailymotion || video_youtube ? (
            <img
              alt={titleShort ? titleShort : "image title"}
              className="hero_image"
              src={renderImageUrl(daily_motion_image, false)}
              loading="lazy"
            />
          ) : (
            <img
              alt={titleShort ? titleShort : "image title"}
              className="hero_image"
              loading="lazy"
              src={
                heroType === "gallery"
                  ? renderImageUrl(galleryPreviewImage, false)
                  : renderImageUrl(image, false)
              }
              srcSet={
                heroType === "gallery"
                  ? `
              
              ${renderImageUrl(galleryPreviewImage, true)}   540w,
              ${renderImageUrl(galleryPreviewImage, false)}   840w,

          `
                  : `
              
          ${renderImageUrl(image, true)}   540w,
          ${renderImageUrl(image, false)}   840w,

      `
              }
            />
          )}
        </a>
      <div className="inner_wrapper">
        <div className="tab_wrapper">
          {breakingNews && <div className="breaking_news"> Breaking news</div>}
          {isSponsored && (
            <div className="tab tab_sponsored">
              <p>sponsored</p>
            </div>
          )}
          {!isSponsored && !breakingNews && (
            <>
            {article.hierarchy.slice(-1).map((item: any, i: number) => {
              return (
                <Fragment key={i}>
                  <a className="tab" href={preview ? "#" : item.path}>
                    <div key={i}>{item.name}</div>
                  </a>
                </Fragment>
              );
            })}
            </>
          )}
        </div>
        <a style={{ display: "flex" }} href={preview ? "#" : path}>
          <h1 className="title_wrapper">{titleShort}</h1>
        </a>
        <div className="hero_body">
          <a href={preview ? "#" : path}>
            <div>{parse(description)}</div>
          </a>
        </div>
      </div>
    </HeroWrapper>
  );
};

export default Hero;
