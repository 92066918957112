const GalleryIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      color="white"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: "white" }}
    >
      <path
        fill="none"
     
        strokeWidth="2"
        d="M1,1 L19,1 L19,19 L1,19 L1,1 Z M5,19 L5,23 L23,23 L23,5.97061363 L18.9998921,5.97061363 M6,8 C6.55228475,8 7,7.55228475 7,7 C7,6.44771525 6.55228475,6 6,6 C5.44771525,6 5,6.44771525 5,7 C5,7.55228475 5.44771525,8 6,8 Z M2,18 L7,12 L10,15 L14,10 L19,16"
      />
    </svg>
  );
};

export default GalleryIcon;
